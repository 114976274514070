// custom Vernonia theme based on bootswatch flatly theme
@import "variables";
@import "./../node_modules/bootstrap/scss/bootstrap";
@import "bootswatch";

@import "./headerBackground";

////////////////////////////////////////////////////////
// global
////////////////////////////////////////////////////////
body {
  font-size: 14px; // all sizing based on body font-size
}

// anchors
a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
a.dropdown-item:hover,
a.btn:hover {
  text-decoration: none;
}

// give headings some more room
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

// sticky list groups or anything else that needs to stick
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 1rem;
}

.page-logo-container {
  position: relative;

  .page-logo {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    width: 16vw;
    min-width: 4rem;
    opacity: 0.2;
    filter: invert(50%) sepia(6%) saturate(2318%) hue-rotate(62deg) brightness(107%) contrast(80%);
  }
}

////////////////////////////////////////////////////////
// 404
////////////////////////////////////////////////////////
.not-found {
  margin: 3rem 0;
  text-align: center;

  h1 {
    font-size: 3rem;
  }

  .btn {
    margin: 0 0.5rem;
  }
}

////////////////////////////////////////////////////////
// alert marquee
////////////////////////////////////////////////////////
.marquee-container {
  z-index: 700;
  width: 100%;
  height: 50px;
  background-color: $info;
  font-size: 20px;
  line-height: 50px;
  overflow: hidden;
  a {
    margin: 20px;
  }
  a,
  a:hover {
    color: $white;
    text-decoration: none;
  }
  &.sticky {
    top: 0;
  }
}

////////////////////////////////////////////////////////
// header and nav
////////////////////////////////////////////////////////
$nav-color: rgba(255, 255, 255, 0.75);
$nav-hover-active-color: rgba(255, 255, 255, 1);
$nav-toggler-border-color: rgba(255, 255, 255, 0.5);

.navbar {
  background: url(#{$header-background}), center, center,
    linear-gradient(90deg, rgba($vernonia-dark-green, 1) 0%, rgba($vernonia-green, 1) 100%);

  .navbar-logo {
    height: 2rem;
    color: $nav-color;
  }

  .navbar-nav {
    color: $nav-color;
    --bs-navbar-active-color: #{$nav-hover-active-color};
    --bs-nav-link-color: #{$nav-color};
    --bs-nav-link-hover-color: #{$nav-hover-active-color};
  }

  .nav-link:hover,
  .nav-link:focus {
    color: $nav-hover-active-color !important;
  }

  .navbar-toggler {
    border-color: $nav-toggler-border-color;
    &:focus {
      box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.25);
    }
  }

  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

////////////////////////////////////////////////////////
// carousel and parallax
////////////////////////////////////////////////////////
.carousel {
  overflow: hidden;
  margin-bottom: 3rem;
  .carousel-item {
    min-height: 260px;
  }
  &.parallax-window {
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  h2,
  p {
    text-shadow:
      #000 1px 1px 8px,
      #000 1px 1px 12px,
      #000 1px 1px 16px,
      #000 1px 1px 20px,
      #000 1px 1px 24px;
  }
  p button,
  p a {
    text-shadow: none;
  }
}

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}

// non-carousel parallax
.parallax-header {
  height: 220px;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 2rem;
}

////////////////////////////////////////////////////////
// page lead
////////////////////////////////////////////////////////
.page-lead {
  margin: 0 auto;
  text-align: center;
  max-width: 900px;

  .lead {
    font-size: 1.1rem;
  }
}

////////////////////////////////////////////////////////
// page content
////////////////////////////////////////////////////////
main {
  &.page-content {
    margin-top: 3rem;
  }

  hr {
    margin: 3rem 0;
    border: 0;
    height: 1px;
    background-color: $secondary;
  }

  .menu-image {
    margin-top: 2rem;
  }

  // required *
  .asterisk {
    color: $danger;
  }

  // title-3 lists
  &.page-title-3 {
    ul {
      list-style: none;
    }
    li {
      font-size: 1.05rem;
      margin: 0.25rem 0;
    }
  }
}

////////////////////////////////////////////////////////
// footer
////////////////////////////////////////////////////////
footer {
  .container {
    margin-top: 4rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    border-top: 1px solid $border-color;
  }
  .row {
    margin: 0 0 2rem;
  }
  .footer-links {
    font-size: 1.5rem;
    a {
      margin: 0 0.25rem;
    }
    .fa-facebook-square {
      color: $blue;
    }
    .fa-youtube {
      color: $red;
    }
    .fa-github {
      color: $black;
    }
    .fa-rss {
      color: $orange;
    }
  }
  p {
    text-align: center;
    margin-bottom: 1rem;
  }
  .fa-heart {
    color: $red;
  }
  .fa-coffee {
    color: #be9969;
  }
}

////////////////////////////////////////////////////////
// staff profiles
////////////////////////////////////////////////////////
.staff-profile {
  margin: 2em 0;
}
.staff-image,
.staff-info {
  display: inline-block;
  vertical-align: middle;
}
.staff-info {
  margin-left: 1em;
  line-height: 1.6em;
}
.staff-image img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
}
.staff-icon-bg {
  width: 68px;
  height: 68px;
  padding-top: 17px;
  text-align: center;
  background-color: $primary;
  color: #fff;
  border-radius: 50%;
}
.staff-icon {
  font-size: 30px;
}

////////////////////////////////////////////////////////
// announcements
////////////////////////////////////////////////////////
// cards for listing announcements in grids or lists
.announcement-card {
  padding-bottom: 18px;
  .text-muted {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
}
.row {
  .announcement-card {
    padding: 15px 18px;
  }
}

// share buttons in announcements
.post-content {
  .post-share {
    display: flex;
    flex-flow: row;
    gap: 1rem;
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }

  .fa-facebook {
    color: #4267b2;
  }

  .fa-twitter {
    color: #1da1f2;
  }

  .fa-envelope {
    color: var(--bs-gray-500);
  }
}

////////////////////////////////////////////////////////
// calendar
////////////////////////////////////////////////////////
div[data-home-event-list=""] {
  margin: 1rem 0;
}

:root {
  --fc-today-bg-color: rgba(0, 0, 0, 0.025);
}

// event colors
.council-events .fc-list-event-dot {
  border: calc(var(--fc-list-event-dot-width) / 2) solid $indigo;
}
.council-events .fc-daygrid-event-dot {
  border: calc(var(--fc-daygrid-event-dot-width) / 2) solid $indigo;
}
.council-events.fc-h-event {
  background-color: $indigo;
  border: 1px solid $indigo;
}

.city-events .fc-list-event-dot {
  border: calc(var(--fc-list-event-dot-width) / 2) solid $cyan;
}
.city-events .fc-daygrid-event-dot {
  border: calc(var(--fc-daygrid-event-dot-width) / 2) solid $cyan;
}
.city-events.fc-h-event {
  background-color: $cyan;
  border: 1px solid $cyan;
}

.library-events .fc-list-event-dot {
  border: calc(var(--fc-list-event-dot-width) / 2) solid $teal;
}
.library-events .fc-daygrid-event-dot {
  border: calc(var(--fc-daygrid-event-dot-width) / 2) solid $teal;
}
.library-events.fc-h-event {
  background-color: $teal;
  border: 1px solid $teal;
}

// event color key
.event-color-key {
  display: flex;
  flex-flow: row;
  gap: 2rem;

  .event-type {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 0.5rem;
  }

  .event-swatch {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
  }

  .council-events {
    background-color: $indigo;
  }

  .city-events {
    background-color: $cyan;
  }

  .library-events {
    background-color: $teal;
  }
}

// calendar buttons
.fc .fc-button-primary {
  background-color: $green;
  border-color: $green;
  &:hover {
    background-color: #56774d;
    border-color: #56774d;
  }
  &:not(:disabled):active {
    background-color: #56774d;
    border-color: #56774d;
  }
  &:focus,
  &:not(:disabled):active:focus {
    box-shadow: none;
  }
}

////////////////////////////////////////////////////////
// back to top button
////////////////////////////////////////////////////////
#back-to-top {
  border-radius: 4px;
  box-shadow: none;
  svg {
    transform: scale(1.5);
  }
}

////////////////////////////////////////////////////////
// document grids
////////////////////////////////////////////////////////
.document-grid-item {
  display: flex;
  flex-flow: column;
  padding: 1.5rem;
  main {
    display: flex;
    flex-flow: row;
    align-items: center;
    img {
      width: 85px;
      height: 110px;
      margin-right: 1rem;
      // border: 1px solid rgba(0, 0, 0, 0.5);
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25), -1px -1px 6px rgba(0, 0, 0, 0.125);
    }
    div {
      align-items: center;
    }
  }
}
